/**
 * Конфигурирование axios для запросов к генератору отчетов
 */
import Vue from 'vue'

import store from '@/store'
import axios from 'axios'

const birt_axios = axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_BIRT_URL,
    responseType: 'blob',
})


birt_axios.interceptors.request.use((config) => {
    const token = store.getters['login/token'];
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});

birt_axios.interceptors.response.use(
    response => {

        const data = response.data;
        const blob=new Blob([data], { type: "application/pdf" });
        const link=document.createElement('a');
        link.href=window.URL.createObjectURL(blob);
        link.target = "_blank"
        link.click();

        return Promise.resolve(response.data)
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            store.dispatch('login/clear_code').then(() => location.reload())
        }
        const data = error.response ? error.response.data ? error.response.data : error.response : error
        return Promise.reject(data);
    }
);

Vue.prototype.$birt_axios = birt_axios

export default birt_axios;