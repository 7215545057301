/**
 * Модуль Vuex
 */
import Vue from 'vue'
import Vuex from 'vuex'

import login from "./modules/login"
import mainPage from "./modules/main-page"
import property from "./modules/property"
import voting from "./modules/voting"
import questions from "@/store/modules/questions";
import user from "@/store/modules/user";
import users from "@/store/modules/users";
import meta from "@/store/modules/meta";
import menu from "@/store/modules/menu";
import notifications from "@/store/modules/notifications";
import reports from "@/store/modules/reports";

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        login,
        mainPage,
        property,
        voting,
        questions,
        user,
        users,
        meta,
        notifications,
        menu,
        reports,
     },
 })

export default store
