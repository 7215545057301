/**
 * Запросы, связанные с уведомлениями (в sidebar)
 */

export default $axios => ({

    getNotifications() {
        return $axios.get(`/my/notification`);
    },

});
