/**
 * Запросы, связанные с логином в систему
 */

const CONTENT_TYPE_FORM = {"Content-Type": "application/x-www-form-urlencoded"}


export default $axios => ({

    getCode(address) {
        return $axios.get("/login/code", {params: {address}} );
    },

    checkCode(address, code) {
        const params = new URLSearchParams();
        params.append("address", address);
        params.append("code", code);

        return $axios.post("/login/code", params, {headers: CONTENT_TYPE_FORM} );
    },

});