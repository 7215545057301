/**
 * Модуль api инкапсулирует общение с сетью
 */
import Vue from 'vue'

import axios from './axios'

import Auth from "@/api/modules/login";
import Meta from "@/api/modules/meta";
import Users from "@/api/modules/users";
import Property from "@/api/modules/property";
import Voting from "@/api/modules/voting";
import Questions from "@/api/modules/questions";
import Files from "@/api/modules/files";
import Validation from "@/api/modules/validation";
import Notifications from "@/api/modules/notifications";
import Debug from "@/api/modules/debug";

const repositories = {
    login: Auth(axios),
    meta: Meta(axios),
    users: Users(axios),
    property: Property(axios),
    voting: Voting(axios),
    questions: Questions(axios),
    files: Files(axios),
    validation: Validation(axios),
    notifications: Notifications(axios),
    debug: Debug(axios),
}

Vue.prototype.$api = repositories

export default repositories;
