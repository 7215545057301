/**
 * Запросы, связанные с справочниками
 */

export default $axios => ({

    getAlgorithms() {
        return $axios.get(`/meta/algorithms`);
    },

    getRoles() {
        return $axios.get(`/meta/roles`);
    },

    getRegions() {
        return $axios.get(`/meta/regions`);
    },

});