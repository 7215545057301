/**
 * Общеупотребительные фильтры
 */
import Vue from 'vue';
import moment from 'moment';

Vue.filter('ru_date_short', value => {
    return moment(value).format("DD.MM.YY");
});

Vue.filter('ru_date', value => {
    return value ? moment(value).format("DD.MM.YYYY") : ""
});

Vue.filter('ru_date_at', value => {
    return value ? moment(value).format("DD.MM.YYYY в HH:mm") : ""
});

Vue.filter('float2', value => {
    return value ? Number(value).toFixed(2) : ""
});
Vue.filter('float2_0', value => {
    return value ? Number(value).toFixed(2) : "0"
});
Vue.filter('float0', value => {
    return value ? Number(value).toFixed(0) : ""
});


Vue.filter('vote_name', value => {

    return value === 'agree' ? 'ЗА' :
            value === 'disagree' ? 'ПРОТИВ' :
            value === 'abstain' ? 'ВОЗДЕРЖАЛСЯ' :
            value
});


Vue.filter('code_mode_name', value => {

    return value === 'SMS' ? 'СМС' :
           value === 'EMAIL' ? 'Email' :
           value
});

Vue.filter('item_address', value => {
    return value.propertyType.objType !== 'Иное'
            ? value.propertyType.value + '. ' + value.objNumber
            : value.objNumber
});

