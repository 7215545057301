/**
 * Запросы, связанные с проверкой проверочного кода
 */
export default $axios => ({

    validateToken(token) {
        return $axios.get(`/check_token?token=${token}`);
    },

});
