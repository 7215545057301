/**
 * Методы, связанные со входом в систему
 */
import api from '@/api'
import Vue from "vue";
import jwt_decode from "jwt-decode";

const EMAIL_KEY = "email";
const TOKEN_KEY = "token";

Vue.prototype.$is_admin = () => !!state.token_decoded.is_admin;

const email_stored = localStorage.getItem(EMAIL_KEY);
const token_stored = localStorage.getItem(TOKEN_KEY);

const state = {
    code_ttl: null,
    code_mode: null,
    next_attempt: null,
    email: email_stored,
    token: token_stored,
    token_decoded: token_stored ? jwt_decode(token_stored) : null,
}

const getters = {
    email: (state) => state.email,
    code_ttl: (state) => state.code_ttl,
    code_mode: (state) => state.code_mode,
    next_attempt: (state) => state.next_attempt,
    is_authenticated: (state) => {
        let b = !!state.token;
        console.log('is_authenticated', b)
        return b; },
    token: (state) => state.token,
    token_decoded: (state) => state.token_decoded,
}

const mutations = {

    setEmail(state, email) {
        state.email = email;
        email ? localStorage.setItem(EMAIL_KEY, email) : localStorage.removeItem(EMAIL_KEY)
    },

    setToken(state, token) {
        state.token = token;
        state.token_decoded = token ? jwt_decode(token) : null
        token ? localStorage.setItem(TOKEN_KEY, token) : localStorage.removeItem(TOKEN_KEY)
    },
}

const actions = {
    get_code({state, commit}, email) {
        return api.login.getCode(email).then(data => {
            commit('setEmail', email);
            state.code_mode = data.mode;
            state.code_ttl = data.codeTTL;
            state.next_attempt = data.codeAttempExpires;
            return data
        })
    },

    check_code({state, commit}, code) {
        return api.login.checkCode(state.email, code).then(data => {
            state.code_ttl = null;
            state.next_attempt = null;
            commit('setToken', data);
            return data
        })
    },

    clear_code({commit}) {
        commit('setEmail', null);
        commit('setToken', null);
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
