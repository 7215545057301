/**
 * Отладочные вызовы для маниуляции статусом голосования
 */
export default $axios => ({

    votingStart(votingId) {
        return $axios.put(`/debug/voting/start?votingId=${votingId}`);
        // return $axios.get(`http://192.168.5.120:8090/debug/voting/start?votingId=19`);
    },

    votingEnd(votingId) {
        return $axios.put(`/debug/voting/end?votingId=${votingId}`);
    },

});
