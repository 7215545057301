/**
 * Методы, связанные с заголовком раздела в верхнем меню
 */
const state = {
    page_title: null,
}

const getters = {
    page_title: (state) => state.page_title || 'Голосование!',
}

const mutations = {

    page_title(state, page_title) {
        state.page_title = page_title;
    },

}

const actions = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
