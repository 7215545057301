/**
 * Конфигурирование axios для запросов к бэкенду
 */
import Vue from 'vue'

import store from '@/store'
import axios from 'axios'

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

axios.interceptors.request.use((config) => {
    const token = store.getters['login/token'];
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});

axios.interceptors.response.use(
    response => {
        return Promise.resolve(response.data)
    },
    (error) => {
        if (error.response ) {
            if (error.response.status === 401) {
                store.dispatch('login/clear_code').then(() => location.reload())
            } else if  (error.response.status === 403) {
                Vue.prototype.$message.error("У вас нет прав для работы с этим ресурсом")
            } else if  (error.response.status === 429) { //429 Too Many Requests («слишком много запросов») используется для кодов подтверждения
                Vue.prototype.$message.error("Слишком частые попытки получить код")
            } else if (error.response.data) {
                console.log(error.response.data)
                if (error.response.data.title)
                    Vue.prototype.$message.error({
                        message: `${error.response.data.title}: ${error.response.data.message}`,
                        dangerouslyUseHTMLString: true
                    })
                else
                    Vue.prototype.$message.error({
                        message: `${error.response.data.message}`,
                        dangerouslyUseHTMLString: true
                    })
            }

        }
        else
            Vue.prototype.$message.error(error.toString())
        const data = error.response ? error.response.data ? error.response.data : error.response : error
        return Promise.reject(data);
    }
);
Vue.prototype.$axios = axios

export default axios;