/**
 * Методы, связанные с уведомлениями
 */
import api from '@/api'
import {getField, updateField} from 'vuex-map-fields';

const state = {
    notifications: {},
}

const getters = {
    getField,
}

const mutations = {
    updateField,

    set_notifications: (state, notifications) => { state.notifications = notifications },
}

const actions = {

    get_notifications({commit}) {
        return api.notifications.getNotifications().then(notifications => {
            commit('set_notifications', _.orderBy(notifications, 'id', 'desc'));//todo перейти на ppn_dt
            return notifications
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
