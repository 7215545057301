/**
 * Методы, связанные с пользователями системы
 */
import api from '../../api'
import {getField, updateField} from "vuex-map-fields";


const state = {
    currentPage: 1,
    search: null,
    users: [],
}

const getters = {
    getField,
}

const mutations = {
    updateField,
    users: (state, users) => {
        state.users = users
    },
    currentUser: (state, currentUser) => {
        state.currentUser = currentUser
    },
}

const actions = {

    getAll({commit}) {

        return api.users.getAll().then(data => {
            data = _.orderBy(data, 'name')
            commit('users', data);
            return data
        })
    },

    add(_, data) {
        return api.users.add(data).then(data => {
            return data
        })
    },
    register(_, {id, data}) {
        return api.users.register(id, data).then(data => {
            return data
        })
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
