/**
 * Запросы, связанные с пользователем и его данными
 */
export default $axios => ({

    getMe() {
        return $axios.get(`/my/userinfo`);
    },

    getMyVotes(voting_id) {
        return $axios.get(`/my/voting/${voting_id}/votes`);
    },

    getUser(id) {
        return $axios.get(`/users/${id}`);
    },

    getUserByEmail(id) {
        return $axios.get(`/users/email/${id}`);
    },

    getUserProps(id) {
        return $axios.get(`/users/${id}/properties`);
    },

    getAll() {
        return $axios.get(`/users`);
    },

    add(data) {
        return $axios.post(`/users`, data);
    },
    acceptPolicyAgreement() {
        return $axios.post(`/my/agreement`);
    },

    register(id, data) {
        return $axios.put(`/users/${id}/register`, data);
    },

    saveUserRole(id, role_cd) {
        return $axios.post(`/users/${id}/role`, role_cd, {headers: {'Content-Type': 'text/plain'}});
    },

    saveUserField(id, field, value, type) {
        return $axios.post(`/users/${id}/field/${field}`, value, {headers: {'Content-Type': 'text/plain', 'X-Datatype': type}});
    },

    saveUXPField(id, uxp_id, field, value, type) {
        return $axios.post(`/users/${id}/uxp/${uxp_id}/field/${field}`, value, {headers: {'Content-Type': 'text/plain', 'X-Datatype': type}});
    },

    savePropertyField(id, property_id, field, value, type) {
        return $axios.post(`/users/${id}/property/${property_id}/field/${field}`, value, {headers: {'Content-Type': 'text/plain', 'X-Datatype': type}});
    },

    addUserProperties(id, data) {
        return $axios.post(`/users/${id}/properties`, data);
    },

    removeUserProperties(user_id, uxp_id) {
        return $axios.delete(`/users/${user_id}/property/${uxp_id}`);
    },

    getCode(user_id) {
        return $axios.get(`/users/${user_id}/confirmation-code`)
    },

    setAdmin(code, user_id) {
        const formData = new FormData()
        formData.append("code", code)
        return $axios.post(`/users/${user_id}/set-admin`, formData)
    },

    remove(code, user_id) {
        const formData = new FormData()
        formData.append("code", code)
        return $axios.post(`/users/${user_id}/remove`, formData)
    },


});
