/**
 * Выделение найденного текста для поиска
 */
import Vue from 'vue';

Vue.prototype.$highlight = (d, search) => {

    if (search) {
        if (!d) return "";
        search = search.trim().replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&');
        const r = new RegExp(search, "gi");
        return search.length ? d.replace(r, "<span class='highlight'>$&</span>") : d
    } else
        return d;

}