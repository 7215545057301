/**
 * Запросы, связанные с собственностью
 */

export default $axios => ({

    getAll() {
        return $axios.get(`/property`);
    },

    getLawTypes() {
        return $axios.get(`/lawType`);
    },

    getMy() {
        return $axios.get(`/my/property`);
    },

    addNewRealty(data){
        return $axios.post(`/property`, data);
    },

    savePropertyField(property_id, field, value, type) {
        return $axios.post(`/property/${property_id}/field/${field}`, value, {headers: {'Content-Type': 'text/plain', 'X-Datatype': type}});
    },

    savePropertyRegion(property_id, value) {
        console.log('value', value)
        return $axios.post(`/property/${property_id}/region`, value, {headers: {'Content-Type': 'text/plain'}});
    },

    setAdmin(property_id, itemParams) {
        return $axios.post(`/property/${property_id}/admin`, itemParams);
    },

    setRuler(property_id, itemParams) {
        return $axios.post(`/property/${property_id}/ruler`, itemParams);
    },

    getCode(user_id) {
        return $axios.get(`/property/${user_id}/confirmation-code`)
    },

    remove(code, property_id) {
        const formData = new FormData()
        formData.append("code", code)
        return $axios.post(`/property/${property_id}/remove`, formData)
    },

});
