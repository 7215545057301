/**
 * Методы, связанные с вопросами голосования
 */
import api from '../../api'
import {cacheAction} from 'vuex-cache';

const state = {
    questions: [],
    templateQuestions: [],
}

const getters = {
    questions: (state) => state.questions,
    templateQuestions: (state) => state.templateQuestions,
}

const actions = {
    LOAD_TEMPLATES: ({commit}) => {

        api.questions.getTemplates()
        .then(templates => {
            const t = templates.map(d => ({
                indeterminate: false,
                checked: false,
                title: d.title,
                data: (d.questions || []).map(v => ({
                    checked: false,
                    algorithm: v.algorithmCd,
                    files: [],
                    text: v.title
                }))
            }))
            commit('SET_TEMPLATES', t);
        })
    },
    SET_QUESTIONS: ({commit}, payload) => {
        commit('SET_QUESTIONS', payload);
    },
    SET_OWN_QUESTION: ({commit}, payload) => {
        commit('SET_OWN_QUESTION', payload);
    },
    CLEAR_QUESTIONS: ({commit}) => {
        commit('CLEAR_QUESTIONS');
    },
    getAlgorithms: cacheAction((_) => api.meta.getAlgorithms()),

}
const mutations = {
    SET_TEMPLATES: (state, templates) => {
        state.templateQuestions = templates;
    },
    SET_OWN_QUESTION: (state, payload) => {
        state.questions.push(payload);
    },
    SET_QUESTIONS: (state, payload) => {
        state.questions.push(...payload);
    },

    CLEAR_QUESTIONS: (state) => {
        state.questions = []
    },
}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
