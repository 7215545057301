/**
 * Методы, связанные со справочниками
 */
import api from '../../api'
import {cacheAction} from 'vuex-cache';

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {

    getAlgorithms: cacheAction((_) => api.meta.getAlgorithms()),

    getRoles: cacheAction((_) => api.meta.getRoles()),

    getRegions: cacheAction(() => api.meta.getRegions()),

}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
