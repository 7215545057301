import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import('../views/Root'),
        beforeEnter: (to, from, next) => {
            store.dispatch("user/get_me").then(me => {
                if (!me.pdpAgree) {
                    router.replace({name: "PrivacyAgreement"})
                } else {
                    next()
                }
            })
        },
        children: [{
            path: "",
            name: 'votes',
            component: () => import('@/views/Votes'),
            meta: {title: "Голосования",},
        }, {
            path: "vote/:id",
            name: 'vote',
            props: true,
            component: () => import('@/views/Vote'),
            meta: {title: "Голосование",},
        }, {
            // Здесь открываются файлы из уведомлений
            path: "voting/file/:id",
            beforeEnter: to => {
                const backend = process.env.VUE_APP_BACKEND_URL;
                const id = to.params.id
                const href = `${backend}voting/file/${id}`

                let link = document.createElement('a');
                link.href = href
                document.body.appendChild(link);
                link.click();
                link.close();
            },
        }, {
            path: "properties",
            name: 'properties',
            component: () => import('@/views/Properties'),
            meta: {title: "Недвижимость",},
        }, {
            path: "users",
            name: 'users',
            component: () => import('@/views/Users'),
            meta: {title: "Пользователи",},
        }, {
            path: "user/:id",
            name: 'user',
            props: true,
            component: () => import('@/views/User'),
            meta: {title: "Карточка Пользователя",},
        }, {
            path: "profile",
            name: 'profile',
            component: () => import('@/views/Profile'),
            meta: {title: "Личный кабинет",},
        }, {
            path: "validation/:token",
            name: 'validation',
            component: () => import('@/views/Validation'),
            meta: {title: "Валидация",},
        }, {
            path: "notifications",
            name: 'notifications',
            component: () => import('@/views/Notifications'),
            meta: {title: "Уведомления",},
        },]
    },
    {
        path: '/code',
        name: 'code',
        component: () => import('../views/Code'),
        meta: {loginPage: true, dontNeedAuth: true, dontNeedEmail: true}
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login'),
        meta: {loginPage: true, dontNeedAuth: true}
    },
    {
        path: '/privacy_policy',
        name: 'PrivacyPolicy',
        component: () => import('../views/PrivacyPolicy'),
        meta: {dontNeedAuth: true, dontNeedEmail: true}
    },
    {
        path: '/privacy_agreement',
        name: 'PrivacyAgreement',
        component: () => import('../views/PrivacyAgreement'),
        meta: {dontNeedAuth: true, dontNeedEmail: true}
    },
    {
        path: '/about_cookie',
        name: 'AboutCookie',
        component: () => import('../views/AboutCookie'),
        meta: {dontNeedAuth: true, dontNeedEmail: true}
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {

    console.log('to', to)

    const requiresAuth = !to.meta.dontNeedAuth
    const requiresEmail = !to.meta.dontNeedEmail

    const isAuthenticated = store.getters["login/is_authenticated"]
    const hasEmail = store.getters["login/email"]

    if (requiresAuth && !isAuthenticated) {
        router.replace({name: "code"})
    } else if (requiresEmail && !hasEmail) {
        router.replace({name: "code"})
    } else {
        next()
    }
})

router.afterEach((to) => {
    const page_title = to.meta && to.meta.title ? to.meta.title : null;
    store.commit("mainPage/page_title", page_title);
    if (Vue.prototype.$isMobile()) store.commit('menu/setMenu')
})

export default router
