/**
 * Запросы, связанные со скачиванием файлов
 */
import FileDownload from 'js-file-download';


export default $axios => ({

    download(url, filename) {
        return $axios.get(url, {responseType: 'blob'})
        .then(response => FileDownload(response, filename))
    },

});