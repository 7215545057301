/**
 * Запросы, связанные с голосованием
 */
export default $axios => ({

    getBaseUrl() {
        return process.env.VUE_APP_BACKEND_URL;
    },

    getAll() {
        return $axios.get(`/voting`);
    },

    getById(id) {
        return $axios.get(`/voting/${id}`);
    },

    getDocs(id) {
        return $axios.get(`/voting/${id}/documents`);
    },

    post(data, files) {
        const formData = new FormData()

        formData.append("data", new Blob([JSON.stringify(data)], {type: "application/json"}))

        files.forEach((file, idx) => formData.append(idx, file))

        return $axios.post(`/voting`, formData);
    },

    getCode(voting_id) {
        return $axios.get(`/voting/${voting_id}/confirmation-code`)
    },

    getActionCode(voting_id, action) {
        return $axios.get(`/voting/${voting_id}/code/${action}`)
    },

    setStatus(voting_id, code, status, additionalInfo) {
        const params = new URLSearchParams();
        params.append("code", code);
        if (additionalInfo)
            params.append("additionalInfo", JSON.stringify(additionalInfo));
        return $axios.post(`/voting/${voting_id}/status/${status}`, params)
    },

    getVoteCode(voting_id) {
        return $axios.get(`/voting/${voting_id}/vote-code`)
    },

    setVote(voting_id, votes, code) {

        const formData = new FormData()
        formData.append("code", code)
        formData.append("votes", new Blob([JSON.stringify(votes)], {type: "application/json"}))

        return $axios.post(`/voting/${voting_id}/votes`, formData)
    },

    makeProtocol(voting_id, code) {

        const formData = new FormData()
        formData.append("code", code)

        return $axios.post(`/voting/${voting_id}/protocol`, formData)
    },

    uploadPhotoAct(voting_id, code, photoAct) {

        const formData = new FormData()
        formData.append("code", code)
        formData.append("photoAct", photoAct)

        return $axios.post(`/voting/${voting_id}/photo-act`, formData)
    },

    setOfflineVote(voting_id, votes, code, proxyDoc, proxyName, proxyNumber, proxyDate, offlineDoc, user_id, user_birth_date) {

        if (!FormData.prototype.appendIfDefined)
            FormData.prototype.appendIfDefined = function (name, value) {
                if (value !== null && value !== undefined)
                    this.append(name, value)
            }

        const formData = new FormData()
        formData.append("code", code)
        formData.append("votes", new Blob([JSON.stringify(votes)], {type: "application/json"}))

        formData.appendIfDefined("proxyDoc", proxyDoc)
        formData.appendIfDefined("proxyName", proxyName)
        formData.appendIfDefined("proxyNumber", proxyNumber)
        formData.appendIfDefined("proxyDate", proxyDate)
        formData.appendIfDefined("offlineDoc", offlineDoc)
        formData.appendIfDefined("user_id", "" + user_id)
        formData.appendIfDefined("user_birth_date", user_birth_date)

        return $axios.post(`/voting/${voting_id}/offline-votes`, formData)
    },

    getVoteStatistics(voting_id) {
        return $axios.get(`/voting/${voting_id}/statistics`)
    },

    getVoteDecisions(voting_id) {
        return $axios.get(`/voting/${voting_id}/decisions`)
    },

    getUsers(voting_id) {
        return $axios.get(`/voting/${voting_id}/users`)
    },

    saveField(voting_id, field, value) {
        return $axios.post(`/voting/${voting_id}/field/${field}`, value, {headers: {'Content-Type': 'text/plain'}})
    },

    saveQuestionField(voting_id, question_id, field, value) {
        return $axios.post(`/voting/${voting_id}/question/${question_id}/field/${field}`, value, {headers: {'Content-Type': 'text/plain'}})
    },

    votingRemind(voting_id) {
        return $axios.post(`/voting/${voting_id}/remind`)
    },

});
