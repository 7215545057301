/**
 * Методы, связанные с динными залогиненного пользователя
 */
import api from '@/api'
import {getField, updateField} from 'vuex-map-fields';
import {cacheAction} from 'vuex-cache';

const state = {
    me: {},
    currentUser: {
        role: {}
    },
    currentUserProps: [],
}

const getters = {
    getField,

    me: (state) => state.me,

    uxpType: (state) => {
        if (!state.currentUser || !state.currentUser.role) return null
        switch (state.currentUser.role.roleCd) {
            case 'owner_fl':
            case 'owner_org':
            case 'owner_fl_unreg':
                // case 'owner_org_unreg':
                return 'owner';

                // case 'manager_org': return 'ruler';

            default:
                return null;
        }
    },

    needPropertyDetails: (state, getters) => getters.uxpType === 'owner',

    hasProperty(state, getters) {
        return !!getters.uxpType
    },

    isUnregistred(state) {
        return state.currentUser.role.roleCd === 'owner_fl_unreg' || state.currentUser.role.roleCd === 'owner_org_unreg'
    },

    needEmail(state) {
        switch (state.currentUser.role.roleCd) {
            case 'owner_fl_unreg':
            case 'owner_org_unreg':
            case 'admin':
                return false;
            default:
                return true
        }
    },

    needBirthDate() {
        switch (state.currentUser.role.roleCd) {
            case 'admin':
            case 'validator':
                return false;
            default:
                return true
        }
    },


}

const mutations = {
    updateField,

    set_me: (state, me) => {
        state.me = me
    },
}

const actions = {
    get_me: cacheAction(({commit}) => api.users.getMe()
            .then(me => {
                commit('set_me', me);
                return me
            })),

    get_user({commit}, id) {
        return api.users.getUser(id).then(data => {
            commit('updateField', {path: 'currentUser', value: data});
            return data
        })
    },

    get_props({commit}, user_id) {

        return api.users.getUserProps(user_id).then(data => {
            data = _.orderBy(data, 'uxpId')
            commit('updateField', {path: 'currentUserProps', value: data});
            return data
        })
    },

    save_user_role(_, {user_id, role_cd}) {
        return api.users.saveUserRole(user_id, role_cd)
    },

    save_user_policy() {
        return api.users.acceptPolicyAgreement()
    },

    save_user_field(_, {user_id, field, value, datatype}) {
        return api.users.saveUserField(user_id, field, value, datatype)
    },

    save_uxp_field(_, {user_id, uxp_id, field, value, datatype}) {
        return api.users.saveUXPField(user_id, uxp_id, field, value, datatype)
    },

    save_property_field(_, {user_id, property_id, field, value, datatype}) {
        return api.users.savePropertyField(user_id, property_id, field, value, datatype)
    },

    add_user_properties(_, {user_id, data}) {
        return api.users.addUserProperties(user_id, data)
    },

    remove_user_properties(_, {user_id, uxp_id}) {
        return api.users.removeUserProperties(user_id, uxp_id)
    },


}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
