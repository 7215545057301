/**
 * Методы, связанные с показом меню
 */
const state = {
    isMenuOpened: true,
}

const getters = {
    isMenuOpenedGetter: (state) => state.isMenuOpened,
}

const mutations = {
    setMenu: (state, isMenuOpened) => state.isMenuOpened = isMenuOpened,
}

const actions = {
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
