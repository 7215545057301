import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import AsyncComputed from 'vue-async-computed'

import 'lodash'

import '@/api'
import '@/api/birt'

import '@/plugins/filters'
import '@/plugins/elements-ui'
import '@/plugins/font-awesome.scss'
import '@/plugins/margin_padding_helper_mixin.css'

import '@/./theme.scss'
import '@/plugins/loading'

import '@/plugins/highlights'
import '@/plugins/highlights.scss'

Vue.prototype.$isMobile = function () {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
    } else {
        return false
    }
}

Vue.config.productionTip = false

Vue.use(AsyncComputed)


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
