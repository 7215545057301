/**
 * Крутилка на экране на время долгих сетевых операций
 */
import Vue from 'vue';

Vue.prototype.$dom_loading = (options) => {

    const default_options = {
        fullscreen: true,
        lock: true,
        text: "Обработка...",
        spinner: 'fas el-icon-fa-spinner dom-loading-spinner',
        background: 'rgba(0, 0, 0, 0.5)'
    }

    const active_options =_.extend(default_options, options)

    return Vue.prototype.$loading(active_options);
}