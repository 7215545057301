<template>
    <div id="app">
        <div class="mobile_warning_wrapper" v-if="$isMobile() && $vssHeight > $vssWidth">
            <div class="mobile_warning">
                <span class="material-icons" style="font-size: 116px; margin-bottom: 20px">screen_rotation</span>
                <h1 style="text-transform: uppercase; margin-top: 40px;">Поверните ваше устройство</h1>
                <p>для корректного отображения содержимого сайта</p>
            </div>
        </div>
        <router-view/>
    </div>
</template>

<script>
import VueScreenSize from "vue-screen-size";
import {mapMutations} from "vuex";

export default {
    mixins: [VueScreenSize.VueScreenSizeMixin],
    methods: {
        ...mapMutations("menu", ["setMenu"]),
    },
    watch: {
        $vssWidth: {
            handler(v) {
                if (v < 1000) {
                    this.setMenu(false)
                }
            },
            immediate: true
        }

    }
}
</script>

<style lang="scss">

@import "~@/theme.scss";
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


html, body {
    min-height: 100vh;
    padding: 0;
    margin: 0;
    font-family: $font-main;
}

#app {
    min-height: 100vh;
}

</style>

<style lang="scss">

@import "~@/theme.scss";

.mobile_warning_wrapper {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    background-color: #3d3d3e;
    color: white;
    opacity: 0.95;
    height: 100vh;
    width: 100%;
}

.mobile_warning {
    height: 90%;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

</style>
