/**
 * Подключение фреймворка ElementUI
 */
import Vue from 'vue';
import ElementUI from 'element-ui';

// Перенесено в font-awesome.scss
// import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/ru-RU';


Vue.use(ElementUI, { locale });
