/**
 * Запросы, связанные с шаблонами голосования
 */

export default $axios => ({

    getTemplates() {
        return $axios.get(`/templates`);
    },

});