/**
 * Методы, связанные с собственностью
 */
import api from '../../api'
import {cacheAction} from "vuex-cache";
import {getField, updateField} from "vuex-map-fields";


const state = {
    currentPage: 1,
    search: null,
    properties: [],
    lawTypes: [],
    propertyTypes: [
        {
            objType: 'Квартира',
            value: 'кв'
        },
        {
            objType: 'Бокс',
            value: 'бокс'
        },
        {
            objType: 'Комната',
            value: 'к'
        },
        {
            objType: 'Помещение',
            value: 'пом'
        },
        {
            objType: 'Иное',
            value: 'иное'
        },
    ]
}

const getters = {
    getField,
    properties: (state) => state.properties,
    propertyTypes: (state) => state.propertyTypes,
    lawTypes: (state) => state.lawTypes
}

const mutations = {
    updateField,
    properties: (state, properties) => { state.properties = properties },
    lawTypes: (state, lawTypes) => { state.lawTypes = lawTypes },
}

const actions = {

    getAll({commit}) {

        return api.property.getAll().then(data => {
            data = _(data).map(d => {
                d.isOwnersTableVisible = false
                return d
            }).orderBy('address').value();
            commit('properties', data);
            return data
        })
    },

    getLawTypes({commit}) {

        return api.property.getLawTypes().then(data => {
            data = _(data).orderBy('id').value();
            console.log(data)
            commit('lawTypes', data);
            return data
        })
    },

    getMy: cacheAction((_) => api.property.getMy()),

    save_property_field(_, {property_id, field, value, datatype}) {
        return api.property.savePropertyField(property_id, field, value, datatype)
    },

    save_property_region(_, {property_id, value}) {
        return api.property.savePropertyRegion(property_id, value)
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
